import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import styled from "styled-components";
import { Grid, Menu, MenuItem, MuiThemeProvider } from "@material-ui/core";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import { useHistory } from "react-router";
import AuthService from "../../../components/auth/AuthService";
import {
  annualAuctionProduct,
  annualBidUrl,
  annualMyLatestBidsForUser,
  annualMyRecentBidsForUser,
  getAnnualBidInfo,
  productDownload,
  productTextSearch,
  productUrl,
} from "../../../common/config/app.endpoints";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import update from "immutability-helper";

import {
  StyledBoxContent,
  StyledDropDown,
  StyledLayoutGridMain,
} from "../../../common/materials/GenericStyleMuitable";
import {
  StyledBtnContainer,
  StyledButtonGray,
  StyledButtonHeader,
  StyledBuyitNow,
  StyledCoreDataGridContainer,
  StyledHeaderContainer,
  StyledIconImg,
  StyledLayoutGrid,
  StyledSelectAllButtonHeader,
  StyledTextWrapper,
} from "./DiamondProductStyle";
import Countdown from "react-countdown";

import {
  faEdit,
  faHeart,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  createTheme,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import copy from "copy-to-clipboard";
import {
  StyledButton,
  StyledModal,
} from "../../../common/materials/GenericStyleComponent";
import {
  convertedValue,
  convertedbuyItNowValue,
  getDescription,
  getMyLatestStoredBids,
  getRapNet,
  getReturnMyLatestStoredBid,
} from "../../products/preview/TenderGenericFunc";
import EllipsisText from "../../../common/elements/EllipsisText";
import {
  getAbbreviation,
  openCertificateLinkPopup,
} from "../../../common/GenericFunctions";
import TabLayout from "../../products/preview/TabLayout";
import ProductFilter from "../../products/preview/ProductsFilter";
import ParcelItemFilter from "../../products/preview/ParcelFilter";
import ProductGridDataParent from "./mobileUi/ProductGridDataParent";
import ProductGridDataChildComponent from "./mobileUi/ProductGridDataChildComponent";
import moment from "moment";
import BlockUserModal from "../../products/BlockUser/BlockUserModal";
import AlertReadOnlyUser from "../../../common/elements/AlertReadOnlyUser";
import ProductBiddingUi from "./biddingUi/ProductBiddingUi";
import AnnualBidPopup from "../../products/preview/bidPopupComponent/AnnualBidPopup";
import ApiTimeout from "../../../modules/ApiTimeout";
import PubSub from "pubsub-js";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { AppDispatch } from "../../../redux/store";
import {
  resetAnnualCurrentApiStartCount,
  resetAnnualItemPerPage,
  resetAnnualJewelleryCurrentApiStartCount,
  resetAnnualJewelleryItemPerPage,
  selectAnnualcurrentApiStartCount,
  selectAnnualItemPerPage,
  setAnnualCurrentApiStartCount,
  setAnnualItemPerPage,
} from "../../../redux/slice/commanPagination/commanPaginationSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";
import AnnualActionPageSizeSelectorNew from "../../core-grid/grid-actions/AnnualActionPageSizeSelector";
import {
  resetDiamondAllProductSelectAll,
  resetDiamondGridProductAllProductIndexes,
  resetIsGridView,
  selectAuctionData,
  selectGridView,
  setDiamondAllProductSelectAll,
  setDiamondGridProductAllProductIndexes,
  setIsGridView,
} from "../../../redux/slice/annual/commanAnnualSlice";
import BasicSearchBox from "../../core-grid/grid-actions/BasicSearchBox";
import BuyItNowconfirmation from "./BuyItNow/BuyItNowconfirmation";
import ViewBuyItNowWinnerModal from "./modal/ViewBuyItNowWinnerModal";
import { MsgCode } from "../../../common/constants/NotificationMSGConstants";
import { getPaginationRequest } from "./BuiServices";
import BulkBidFloatingIcon from "./biddingUi/BulkBidFloatingIcon";

const faEditProps = faEdit as IconProp;
const faHeartProps = faHeart as IconProp;
const faShareSquareProps = faShareSquare as IconProp;

const StyledActionBarContainer = styled(Grid)`
  border-radius: 5px;
  justify-content: end;
  padding: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  &:focus {
    outline: none;
  }
  grid-gap: 5px;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
`;

interface IPendingProductProps extends IRouterComponentProps {
  setShowAlertMessage: any;
  setOpenToast: any;
  setAlertType: any;
}

let body: any = [];
let totalElement: number = 0;
let page: number = 0;

let payload: any = {};
let startCount: number = 0;
let endCount: number = 20;
let rowSelectedData: any;

let SelectedTableData: any;
let updatedRowData: any;
let updateRowIndex: any;
let auctionProductResult: any;
let getCurenntPerId: any = "";

let seconds: any = "";
let date: string | string[] = "";
let filter: any = [];
let filteredValues: any = "";
let filteredParcelValues: any = "";
let auctionName: string = "";

let details: any;

let indexsForBulkBidItems: any = [];
let myLatestBidForProduct: any = [];
// auction info
let auctionId: any;
let joinedAuctionUserId: any;
let auctionDate: any;
let isReadOnlyUser: any;
let isAuctionUserId: boolean;
let joinedAuctions: any = [];
let joinedAnnualAuctions: any = [];

const columnIndexes = {
  serialNo: 0,
  perId: 1,
  auctionUserId: 2,
  productCode: 5,
  lab: 6,
  certificate: 7,
  description: 8,
  productDescription: 9,
  carat: 10,
  color: 11,
  clarity: 12,
  remarks: 13,
  noOfPieces: 14,
  shape: 15,
  sieve: 16,
  cutGrade: 17,
  polish: 18,
  symmetryAbbreviation: 19,
  flour: 20,
  currency: 21,
  peicePerCt: 22,
  myLatestBids: 23,
  notes: 24,
  currentBidPrice: 25,
  repnetPrice: 26,
  noOfBids: 27,
  rowColor: 28,
  exchangeRate: 29,
  parcelType: 30,
  noOfBidders: 31,
  currentBidValue: 31,
  hieghestBidValue: 32,
  watched: 32,
  sellerAuctionUserId: 33,
  rapnetPercentage: 34,
  previousHighestBidder: 35,
  productStartDate: 36,
  productExtended: 37,
  holdPreviousBidder: 38,
};
let length: any;
const initialState = {
  mouseX: null,
  mouseY: null,
};
let timeVal: any = 2000;
let bidIndexsArr: any = [];
const DiamondProductAuction = (props: IPendingProductProps) => {
  let { setShowAlertMessage, setOpenToast, setAlertType } = props;

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            background: "white",
          },
          fixedLeft: {
            background: "white",
          },
        },
        MuiTableSortLabel: {
          root: {
            "&$active": {
              color: "white",
              "&& $icon": {
                color: "white",
              },
            },
            "&$inactive": {
              color: "white",
              "&& $icon": {
                color: "white",
              },
            },
          },
        },
      },
    });

  const theme = useTheme();
  let languageCode =
    localStorage.getItem("language") === "EN"
      ? "en"
      : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";

  const [auctionProducts, setAuctionProducts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingNoProduct, setIsLoadingNoProduct] = useState<boolean>(true);
  const [isShowSearchBox, setShowSearchBox] = useState<boolean>(true);
  const [apiTimeout, setApiTimeout] = useState<boolean>(false);
  const [onAction, setOnAction] = useState<boolean>(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [userBlock, setUserBlock] = useState<boolean>(false);
  const [textSearchArray, setTextSearchArray] = useState([]);
  const [optionValue, setOptionValue] = useState("");
  const [isShowParcelItem, setShowParcelItem] = useState<boolean>(false);
  const [showSinglebidPopup, setShowSinglebidPopup] = useState<boolean>(false);
  const [showBulkBidButton, setShowBulkBidButton] = useState<boolean>(false);
  const [uiBoxView, setUiBoxView] = useState<boolean>(false);
  const [isDataRefresh, setIsDataRefresh] = useState<boolean>(false);
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] =
    useState<boolean>(false);
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
  const [showBulkBidPopup, setShowBulkBidPopup] = useState<boolean>(false);
  const [shouldGetAllData, setShouldGetAllData] = useState<boolean>(false);
  const [isExportDisable, setIsExportDisable] = useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isSort, setIsSort] = useState<boolean>(false);
  const [isBuyItNowModal, setIsBuyItNowModal] = useState(false);
  const [currentProductData, setCurrentProductData] = useState<any>(null);
  const [productDetails, setProductDetails] = useState("");
  const [buyItWinnerPop, setBuyItWinnerPop] = useState(false);
  const [tabValue, setTabValue] = useState({
    allProducts: true,
    myBids: false,
  });
  const [currentProductId, setCurrentProductId] = useState<string>("");
  const [productdate, setProductStartDate] = useState<string>("");
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);
  const { t } = useTranslation();
  let history = useHistory();
  const Auth = new AuthService();

  const watchedItemArr: any = useRef<any>(null);
  const eventSource: any = useRef<any>();
  const maxRetryCount = 2;
  const countRef = useRef(0);
  const itemsPerPageUseRef = useRef(20);
  const selectPageUseRef = useRef(0);

  const maxCallApiCount = 1;
  const countAnnualRef = useRef(0);
  const secondTryAnnualRef = useRef(0);
  const dispatch: AppDispatch = useDispatch();
  const openedBuyItNowPopupID: any = useRef(null);
  let isMypage: boolean = false;
  const isGridView: any = useSelector((state: RootState) =>
    selectGridView(state)
  );

  const annualData: any = useSelector((state: RootState) =>
    selectAuctionData(state)
  );

  useEffect(() => {
    dispatch(resetDiamondGridProductAllProductIndexes());
    dispatch(resetDiamondAllProductSelectAll());
    setIsLoading(true);
    localStorage.removeItem("dropDownFilterVal");
    localStorage.removeItem("jwlFilteredValues");
    localStorage.removeItem("filteredJewelleryValues");

    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    joinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);

    if (localStorage.getItem("tabSelectedVal") === "2") {
      setShowParcelItem(true);
    }

    dispatch(resetAnnualJewelleryItemPerPage());
    dispatch(resetAnnualJewelleryCurrentApiStartCount());

    if (joinedAuctions !== null || joinedAuctions?.length !== undefined) {
      joinedAuctions?.filter((items: any) => {
        if (items.category === "Diamonds") {
          auctionId = items.perId;
          joinedAuctionUserId = items.auctionUserId;
          auctionDate = items.startDate;
        }
      });
    } else {
      auctionId = annualData.perId;
      joinedAuctionUserId = annualData.auctionUserId;
      auctionDate = annualData.startDate;
    }

    if (auctionId !== undefined) {
      //Event source Connection to the server
      getAllUpdatedData();
      getMyLatestBids(auctionId);
    } else {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setApiTimeout(false);
      }, 2000);
    }

    // update product msg
    PubSub.subscribe("updateProduct", (msg: any, data: any) => {
      if (data.auctionId === auctionId) {
        if (
          data.message === MsgCode.SCHEDULED_EXTENDED_PRODUCTS ||
          data.message === MsgCode.MARK_END_MESSAGE ||
          data.message === MsgCode.MARKED_END_MESSAGE ||
          data.message === MsgCode.PRODUCTS_AVAILABLE
        ) {
          setIsLoading(true);
          getAuctionProducts(auctionId, selectcurrentApiStartCountValue);
        } else if (data.message === MsgCode.ANNUAL_PRODUCTS_SOLD) {
          setProductDetails(data.productDesc);
          setTimeout(() => {
            if (openedBuyItNowPopupID.current !== null) {
              if (openedBuyItNowPopupID.current?.perId === data.productId) {
                setCurrentProductData(null);
              }
            }

            if (data.winnerId === joinedAuctionUserId) {
              setBuyItWinnerPop(true); // winner model state        
            }
            if (SelectedTableData !== undefined) {
              if (SelectedTableData.perId === data.productId) {
                setShowSinglebidPopup(false);
              }
            }
            // update product when user did buy it now action
            const newArr: any = auctionProductResult.map((obj: any) => {
              if (obj.perId === data.productId) {
                return {
                  ...obj,
                  annualProductState: "Ended",
                };
              }
              return obj;
            });
            auctionProductResult = newArr;
            setAuctionProducts(() => newArr);
          }, 3000);
        }
      }
    });

    return () => {
      if (eventSource.current !== undefined || eventSource.current !== null) {
        if (eventSource.current?.close !== undefined) {
          eventSource.current?.close();
        }
      }
      localStorage.removeItem("urlProductAuctionFromEmail");
    };
  }, []);

  //redux page selector
  const selectcurrentApiStartCountValue: any = useSelector((state: RootState) =>
    selectAnnualcurrentApiStartCount(state)
  );

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectAnnualItemPerPage(state)
  );

  useEffect(() => {
    localStorage.setItem(
      "currentStart",
      JSON.stringify(selectcurrentApiStartCountValue * selectItemPerPageValue)
    );
    if (auctionId !== undefined) {
      getAuctionProducts(auctionId, selectcurrentApiStartCountValue);
    }
  }, [selectItemPerPageValue, selectcurrentApiStartCountValue]);

  useEffect(() => {
    if (shouldGetAllData) {
      getAllUpdatedData();
      setShouldGetAllData(false);
    }
  }, [shouldGetAllData]);

  const getAllUpdatedData = () => {
    if (countRef.current < maxRetryCount) {
      eventSource.current = new EventSource(
        `${getAnnualBidInfo}?auctionId=${auctionId}`
      );
      eventSource.current.onmessage = (event: any) => {
        updatedRowData = JSON.parse(event.data);
        if (updatedRowData !== undefined) {
          if (updatedRowData.alive) {
            //This will listen only for events
          } else {
            if (auctionId === updatedRowData.auctionId) {
              updateRowIndex = auctionProductResult.findIndex(
                (product: any) => product.perId === updatedRowData.perId
              );
              const mapping: any = {};
              if (
                auctionProductResult !== undefined &&
                auctionProductResult.length > 0
              ) {
                auctionProductResult.map((items: any) => {
                  mapping[items.perId] = {
                    watched: items.watched,
                  };
                });
              }
              var updatedArr: any;
              if (updateRowIndex !== -1) {
                updatedArr = update(auctionProductResult, {
                  $splice: [[updateRowIndex, 1, updatedRowData]],
                });
              } else {
                updatedArr = auctionProductResult;
              }
              const arr = [...updatedArr];

              arr.map((items: any) => {
                if (watchedItemArr.current !== null) {
                  let watchedItemIndex = watchedItemArr.current.findIndex(
                    (product: any) => items.perId === product.productId
                  );
                  if (mapping[items.perId] !== undefined) {
                    if (watchedItemIndex !== -1) {
                      items.watched =
                        watchedItemArr.current[watchedItemIndex].watched;
                    } else {
                      items.watched = mapping[items.perId].watched;
                    }
                  }
                } else {
                  if (mapping[items.perId] !== undefined) {
                    items.watched = mapping[items.perId].watched;
                  }
                }
              });
              auctionProductResult = arr;
              setAuctionProducts(auctionProductResult);
              watchedItemArr.current = null;
              setShouldGetAllData(false);
            }
          }
        }
      };
      eventSource.current.onerror = (err: any) => {
        console.error("EventSource failed:", err);
        countRef.current = countRef.current + 1;
        setShouldGetAllData(true);
      };
    }
  };

  const tabItemClicks = (value: String) => {
    if (value === "2") {
      localStorage.setItem("tabSelectedVal", "2");
      setShowParcelItem(true);
    } else {
      localStorage.setItem("tabSelectedVal", "1");
      setShowParcelItem(false);
    }
  };

  const getAuctionProducts = (
    auctionId: number | string,
    currentPage: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    setOpenToast(false);
    body = [];
    filteredValues = "";
    filteredParcelValues = "";

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);

      if (filteredValues !== undefined) {
        if (filteredValues === null || filteredValues.length === 0) {
          filter = null;
        } else {
          filteredValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredValues.splice(index, 1);
            }
          });
          filter = filteredValues;
        }
      }
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);

      if (filteredParcelValues !== undefined) {
        if (
          filteredParcelValues === null ||
          filteredParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredParcelValues.splice(index, 1);
            }
          });
          filter = filteredParcelValues;
        }
      }
    }
    getApiBody(auctionId, currentPage, endCount);
  };

  const getAuctionProductsFirst = (
    auctionId: number | string,
    currentPage: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    setOpenToast(false);
    body = [];
    filteredValues = "";
    filteredParcelValues = "";

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);

      if (filteredValues !== undefined) {
        if (filteredValues === null || filteredValues.length === 0) {
          filter = null;
        } else {
          filteredValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredValues.splice(index, 1);
            }
          });
          filter = filteredValues;
        }
      }
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);

      if (filteredParcelValues !== undefined) {
        if (
          filteredParcelValues === null ||
          filteredParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredParcelValues.splice(index, 1);
            }
          });
          filter = filteredParcelValues;
        }
      }
    }
    setTimeout(() => {
      getApiBody(auctionId, currentPage, endCount);
    }, timeVal);
    if (timeVal <= 5000) {
      timeVal = timeVal + 1000;
    } else {
      timeVal = 2000;
    }
  };

  const getApiBody = async (
    auctionId: string | number,
    currentPage: number,
    endCount: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    payload = {};
    getCurenntPerId = localStorage.getItem("currentPDItem");

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);
    }

    if (
      localStorage.getItem("annualdropDownFilterVal") !== undefined &&
      localStorage.getItem("annualdropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("annualdropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    const rediractProductId: any = localStorage.getItem(
      "currentProductIdOfBidNow"
    );
    const mypageSerialNO: any = localStorage.getItem(
      "currentMypageAnnualPDItem"
    );
    let isMypageProduct: boolean = false;
    const stringId: any = localStorage.getItem("currentAnnualPDItem") || "";
    if (mypageSerialNO === null) {
      if (
        selectcurrentApiStartCountValue !== null &&
        selectcurrentApiStartCountValue !== 0
      ) {
        startCount = selectcurrentApiStartCountValue * selectItemPerPageValue;
      } else {
        startCount = selectcurrentApiStartCountValue;
      }
      isMypageProduct = false;
    } else {
      const { response } = await getPaginationRequest(auctionId, rediractProductId, stringId);
      startCount = response;
      let currentPageOfApiRes = response / selectItemPerPageValue;
      dispatch(setAnnualCurrentApiStartCount({ currentPage: currentPageOfApiRes }));
      isMypageProduct = true;
    }

    if (
      selectcurrentApiStartCountValue != null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      page = selectcurrentApiStartCountValue;
    } else {
      page = 0;
    }

    if (selectItemPerPageValue !== null) {
      let savedCount: any = selectItemPerPageValue;
      endCount = savedCount;
    }

    if (
      (filter === null &&
        (filteredValues == null ||
          filteredValues === undefined ||
          filteredValues === "null" ||
          filteredValues.length === 0)) ||
      (filter === null &&
        (filteredParcelValues == null ||
          filteredParcelValues === undefined ||
          filteredParcelValues === "null" ||
          filteredParcelValues.length === 0))
    ) {
      payload = {
        page: {
          start: isMypageProduct ? startCount : isLoadPreSelectedPage
            ? currentPage * 20
            : selectcurrentApiStartCountValue * selectItemPerPageValue,
          count: selectItemPerPageValue,
          sort: {
            orders:
              property === "serialNo"
                ? [
                  {
                    property:
                      property !== null && property !== undefined
                        ? property
                        : "productEndDate",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                ]
                : [
                  {
                    property:
                      property !== null && property !== undefined
                        ? property
                        : "productEndDate",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                  {
                    property: "serialNo",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                ],
          },
        },
      };
    } else {
      if (filteredValues !== null && filteredValues.length > 0) {
        body = filteredValues ? filteredValues : filter;
      } else {
        body = filteredParcelValues ? filteredParcelValues : filter;
      }

      body.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          body.splice(index, 1);
        }
      });

      if (localStorage.getItem("tabSelectedVal") === "1") {
        payload = {
          filters: {
            or: [],
            and: body,
          },
          page: {
            start: isMypageProduct ? startCount : isLoadPreSelectedPage
              ? currentPage * 20
              : selectcurrentApiStartCountValue * selectItemPerPageValue,
            count: selectItemPerPageValue,
            sort: {
              orders:
                property === "serialNo"
                  ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "productEndDate",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                  : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "productEndDate",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
            },
          },
        };
      } else {
        payload = {
          filters: {
            or: [],
            and: body,
          },
          page: {
            start: isMypageProduct ? startCount : isLoadPreSelectedPage
              ? currentPage * 20
              : selectcurrentApiStartCountValue * selectItemPerPageValue,
            count: selectItemPerPageValue,
            sort: {
              orders:
                property === "serialNo"
                  ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "productEndDate",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                  : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "productEndDate",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
            },
          },
        };
      }
    }

    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      setOptionValue(val);
      payload = {
        filters: {
          and: [],
          or: [
            {
              match: "anywhere",
              name: "productDescription",
              value: val,
            },
            {
              match: "anywhere",
              name: "remarks",
              value: val,
            },
          ],
        },
        page: {
          start: isMypageProduct ? startCount : isLoadPreSelectedPage
            ? currentPage * 20
            : selectcurrentApiStartCountValue * selectItemPerPageValue,
          count: selectItemPerPageValue,
          sort: {
            orders:
              property === "serialNo"
                ? [
                  {
                    property:
                      property !== null && property !== undefined
                        ? property
                        : "productEndDate",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                ]
                : [
                  {
                    property:
                      property !== null && property !== undefined
                        ? property
                        : "productEndDate",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                  {
                    property: "serialNo",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                ],
          },
        },
      };
    }

    callApi(payload);

  };

  const callApi = (payload: any) => {
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.postRequest(
        `${annualAuctionProduct}?auctionType=Annual&category=Diamonds`,
        payload,
        (status: any, response: any) => {
          setIsLoading(true);
          if (status === 200) {
            setAuctionProducts(response.results);
            totalElement = response.totalElements;
            auctionProductResult = response.results;
            setIsLoading(false);
            setIsLoadingNoProduct(false);
            countAnnualRef.current = 0;
            secondTryAnnualRef.current = 1;
            localStorage.removeItem(
              "currentMypageAnnualPDItem"
            );
            if (
              localStorage.getItem("currentAnnualPDItem") !== null &&
              localStorage.getItem("currentAnnualPDItem") !== undefined
            ) {
              const id: any = localStorage.getItem("currentAnnualPDItem") || "";
              var element: any = document.getElementById(id);
              if (element !== undefined && element !== null) {
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "end",
                  inline: "nearest",
                });
              }
              localStorage.removeItem("currentAnnualPDItem");
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 404) {
            setIsLoading(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
            setIsLoadingNoProduct(false);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
            setIsLoadingNoProduct(false);
          } else {
            setIsLoading(false);
            setIsLoadingNoProduct(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  //get all mybids for Individual product

  const getMyLatestBids = (auctionId: string) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${annualMyLatestBidsForUser}?auctionId=${auctionId}`,
        (status: number, response: any) => {
          if (status === 200) {
            myLatestBidForProduct = response;
            setIsLoadingNoProduct(false);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage("Internal Server Error");
            setAlertType("error");
            setOpenToast(false);
          }
        }
      );
    }
  };

  const getMyRecentBids = (auctionId: string, productObj: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${annualMyRecentBidsForUser}?auctionId=${auctionId}`,
        productObj,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            let arrayModify = response;
            arrayModify.concat(
              myLatestBidForProduct?.filter(
                (x: any) =>
                  !arrayModify.find((m: any) => m.productId === x.productId)
              )
            );
            setIsLoadingNoProduct(false);
            setIsSelected(false);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage("Internal Server Error");
            setAlertType("error");
            setOpenToast(false);
          }
        }
      );
    }
  };

  //sorting
  const textSearchListing = (value: String) => {
    payload = {
      filters: {
        or: [
          {
            match: "anywhere",
            name: "remarks",
            value: value,
          },
          {
            match: "anywhere",
            name: "productDescription",
            value: value,
          },
        ],
      },
    };

    if (value === "" || value.length === 1) {
      setTextSearchArray([]);
    } else {
      if (value.length > 1) {
        Auth.postRequest(
          productTextSearch,
          payload,
          (status: any, response: any) => {
            if (status === 200) {
              setTextSearchArray(response.results);
            }
          }
        );
      }
    }
  };

  const autoCompleteSearch = (val: any) => {
    //handle local storege
    localStorage.setItem("optionValue", val);
    if (selectcurrentApiStartCountValue === 0) {
      getAuctionProducts(auctionId, 0);
    } else {
      dispatch(resetAnnualCurrentApiStartCount());
    }
  };

  const handleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
    setShowSearchBox(!isShowSearchBox);
  };

  const filteredAuction = async (
    auctions: any,
    isFilter: boolean,
    values: any,
    isCustomFilter?: any
  ) => {
    localStorage.removeItem("currentStart");
    if (
      localStorage.getItem("annualdropDownFilterVal") !== undefined &&
      localStorage.getItem("annualdropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("annualdropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (values === null) {
      filter = null;
      payload = {
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "productEndDate",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
    } else {
      body = [];
      values.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          values.splice(index, 1);
        }
      });
      values.push({
        match: "exact",
        name: "parcelType",
        value:
          localStorage.getItem("tabSelectedVal") === "1" ? "Diamond" : "Parcel",
      });
      body = values;
      payload = {
        filters: {
          or: [],
          and: body,
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "productEndDate",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
      if (isCustomFilter === undefined) {
        filter = body;
        if (localStorage.getItem("tabSelectedVal") === "1") {
          localStorage.setItem("filteredValues", JSON.stringify(body));
        } else {
          localStorage.setItem("filteredParcelValues", JSON.stringify(body));
        }
      }
    }
    await filterCAllback(payload);
  };

  const filterCAllback = (dataVal: any) => {
    if (selectcurrentApiStartCountValue === 0) {
      callApi(dataVal);
    } else {
      dispatch(resetAnnualCurrentApiStartCount());
    }
  };

  const handleBidClick = (tableData: any, indexVal: any) => {
    rowSelectedData = tableData;
    SelectedTableData = tableData;
    getProductsDetails(SelectedTableData, indexVal);
    details = indexVal;
    //TODO
    setShowSinglebidPopup(true);
  };

  const getProductsDetails = (productsDetails: any, indexVal: any) => {
    length = auctionProducts.length;
    details = indexVal;
    return details;
  };

  //rediract from bid now button of product details page -------
  const renderAutomaticBidPopup = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      const indexOfProduct = auctionProducts.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      if (details !== -1) {
        SelectedTableData = auctionProducts[indexOfProduct];
        getMyLatestBids(auctionId);
      }
      rowSelectedData = data;
      details = indexOfProduct;
      length = auctionProducts.length;
      if (details !== -1) {
        setShowSinglebidPopup(true);
      }
      localStorage.removeItem("currentProductIdOfBidNow");
    }
  };

  const renderAutomaticBidPopupInMobile = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      const indexOfProduct = auctionProducts.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      if (details !== -1) {
        SelectedTableData = auctionProducts[indexOfProduct];
        getMyLatestBids(auctionId);
      }
      rowSelectedData = data;
      details = indexOfProduct;
      length = auctionProducts.length;
      if (details !== -1) {
        setShowSinglebidPopup(true);
      }
      localStorage.removeItem("currentProductIdOfBidNow");
    }
  };

  const handleBtnValue = (
    values: number,
    currentBidPrice: any,
    productId: any,
    myBidValues: any,
    currentIndexValue: any,
    watched: boolean
  ) => {
    bidIndexsArr = [currentIndexValue];

    var data = [
      {
        bidIncreasedBy: values,
        bidPrice: currentBidPrice,
        auctionUserId: joinedAuctionUserId,
        auctionId: auctionId,
        productId: productId,
        watched: watched,
      },
    ];
    var dataProduct = [productId];
    let updateRowIndex = myLatestBidForProduct.findIndex(
      (product: any) => product.productId === myBidValues.productId
    );
    if (updateRowIndex === -1) {
      var myLatestBidForProductObjectData: any = [
        ...myLatestBidForProduct,
        myBidValues,
      ];
      myLatestBidForProduct = myLatestBidForProductObjectData;
    } else {
      var updatedArray = update(myLatestBidForProduct, {
        $splice: [[updateRowIndex, 1, myBidValues]],
      });
      myLatestBidForProduct = updatedArray;
    }
    bidProduct(data, dataProduct);
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
  };

  const bulkBidHandler = (
    values: any[],
    myBidBulkValues: any,
    productids: any,
    bidProductIndexs: any
  ) => {
    bidIndexsArr = bidProductIndexs;
    myLatestBidForProduct = myBidBulkValues;
    let obj: any = productids;
    //saved watched object for local value update
    watchedItemArr.current = values;
    bidProduct(values, obj);
    onGridSelectionChange(0);
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
    dispatch(resetDiamondGridProductAllProductIndexes());
    dispatch(resetDiamondAllProductSelectAll());
    setIsSelected(false);
  };

  const bidProduct = (payloadBid: any, productObj: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        annualBidUrl,
        payloadBid,
        (status: any, response: any) => {
          if (status === 200) {
            getMyRecentBids(auctionId, productObj);
            let isBidIncreasedBy = payloadBid.some((obj: any) =>
              obj.hasOwnProperty("bidIncreasedBy")
            );

            if (!isBidIncreasedBy) {
              bulkPrebidCallingApi(payloadBid);
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const bulkPrebidCallingApi = async (bidobjectarray: any) => {
    const newArr: any = await auctionProducts.map((obj: any) => {
      const bidObj = bidobjectarray.find(
        (bid: any) => bid.productId === obj.perId
      );
      if (bidObj) {
        return {
          ...obj,
          watched: bidObj.watched,
        };
      }
      return obj;
    });
    setAuctionProducts(() => [...newArr]);
  };

  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable: any;
    heightOfInnerTable = h - 240;
    if (auctionProducts.length > 2) {
      return `${heightOfInnerTable}px`;
    }
  };

  const options: MUIDataTableOptions = {
    filter: false,
    download: "false",
    print: "false",
    viewColumns: "false",
    count: totalElement,
    page: page,
    serverSide: true,
    search: false,
    rowsPerPage: selectItemPerPageValue,
    rowsPerPageOptions: [0],
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectableRowsOnClick: false,
    isRowSelectable: (dataIndex: any) => {
      const disableRowIndexsArray = getDisableRowIndex();
      return disableRowIndexsArray.includes(dataIndex) ? false : true;
    },
    onChangePage: (currentPage) => {
      setIsLoading(true);
      localStorage.setItem(
        "currentStart",
        JSON.stringify(currentPage * itemsPerPageUseRef.current)
      );
      dispatch(setAnnualCurrentApiStartCount({ currentPage }));
    },
    onSearchChange: (searchTxt: any) => {
      return searchTxt;
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <div className="mailIconDiv">
          <IconButton color="primary">
            <i className="zmdi zmdi-email"></i>
          </IconButton>
        </div>
      );
    },
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      let productData: any = auctionProducts[rowIndex];
      if (productData.annualProductState === "Ended") {
        return { style: { background: "#d3d3d3" } };
      } else {
        if (productData.previousHighestBidder === undefined) {
          if (productData.holdPreviousBidder === undefined) {
            if (productData.auctionUserId !== joinedAuctionUserId) {
              return { style: { background: "white" } };
            } else {
              return { style: { background: productData.rowColor } };
            }
          } else {
            if (joinedAuctionUserId === productData.holdPreviousBidder) {
              return { style: { background: "#ffeb9c" } };
            } else {
              return { style: { background: productData.rowColor } };
            }
          }
        } else {
          if (joinedAuctionUserId === productData.previousHighestBidder) {
            return { style: { background: "#FBE4D5" } };
          } else {
            return { style: { background: productData.rowColor } };
          }
        }
      }
    },
    onColumnSortChange: (price: any, direction: any) => {
      let order = "";
      if (isSort === false) {
        order = "desc";
      } else {
        order = "asc";
      }
      setIsSort(!isSort);
      sort(price, order);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRowsSelected: any,
      rowsSelected: any
    ) => {
      indexsForBulkBidItems = rowsSelected;
      indexsForBulkBidItems.slice(0, 10).map((indexs: any) => {
        let {
          sellerAuctionUserId,
          previousHighestBidder,
          auctionUserId,
          holdPreviousBidder,
        } = auctionProducts[indexs.index !== undefined ? indexs.index : indexs];
        if (sellerAuctionUserId === auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        } else if (previousHighestBidder !== undefined) {
          if (auctionUserId === previousHighestBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (holdPreviousBidder !== undefined) {
          if (auctionUserId === holdPreviousBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (joinedAuctionUserId === auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        }
      });
      if (indexsForBulkBidItems.length > 0) {
        setShowBulkBidButton(true);
      } else {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length === 0) {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length > 10) {
        setShowAlertMessage(
          `${t("common:Tender:validation:productSelection")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    },
    tableBodyHeight: getTableBodyHeight(),
    textLabels: {
      body: {
        noMatch: isLoadingNoProduct
          ? "Loading data..."
          : `${t("common:preview:noProductFound")}`,
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
  };

  const onGridSelectionChange = (rowsSelected: any) => {
    indexsForBulkBidItems = rowsSelected;
    setOpenToast(false);
    if (indexsForBulkBidItems.length >= 0) {
      setShowBulkBidButton(true);
    } else {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length === 0) {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length > 10) {
      setShowAlertMessage(`${t("common:Tender:validation:productSelection")}`);
      setAlertType("error");
      setOpenToast(true);
    }
  };

  const boxColor = (items: any) => {
    const {
      auctionUserId,
      rowColor,
      previousHighestBidder,
      holdPreviousBidder,
      annualProductState
    } = items;
    if (annualProductState === "Ended") {
      return { style: { background: "#d3d3d3" } };
    } else {
      if (previousHighestBidder === undefined) {
        if (holdPreviousBidder === undefined) {
          if (joinedAuctionUserId !== auctionUserId) {
            return "white";
          } else {
            return rowColor;
          }
        } else {
          if (joinedAuctionUserId === holdPreviousBidder) {
            return "#ffeb9c";
          } else {
            return rowColor;
          }
        }
      } else {
        if (joinedAuctionUserId === previousHighestBidder) {
          return "#FBE4D5";
        } else {
          return rowColor;
        }
      }
    }
  };

  const getDisableRowIndex = () => {
    return auctionProducts.map((items: any, index: number) => {
      if (isReadOnlyUser) {
        return index;
      } else if (items.annualProductState === "Ended") {
        return index;
      } else {
        if (items.sellerAuctionUserId === joinedAuctionUserId) {
          return index;
        }
      }
    });
  };

  // API calls
  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        getExtendedTime();
        return <span>00:00:00:00</span>;
      }
    } else {
      if (
        days === "00" &&
        hours === "00" &&
        Number(minutes) >= 30
      ) {
        return (
          <span style={{ color: "red" }}>
            {days}:{hours}:{minutes}:{seconds}
          </span>
        );
      } else {
        return (
          <span>
            {days}:{hours}:{minutes}:{seconds}
          </span>
        );
      }
    }
  };

  const getExtendedTime = () => {
    if (!countAnnualRef.current) {
      if (secondTryAnnualRef.current === 0) {
        getAuctionProducts(auctionId, selectcurrentApiStartCountValue);
      } else {
        getAuctionProductsFirst(auctionId, selectcurrentApiStartCountValue);
      }
      countAnnualRef.current = countAnnualRef.current + 1;
    }
    closebidPopup();
  };

  const getExtendedTimeGrid = (cPage: any) => {
    if (!countAnnualRef.current) {
      if (secondTryAnnualRef.current === 0) {
        getAuctionProducts(auctionId, cPage);
      } else {
        getAuctionProductsFirst(auctionId, cPage);
      }
      countAnnualRef.current = countAnnualRef.current + 1;
    }
    closebidPopup();
  };

  const productTimeLeft = (startDate: any) => {
    let date: string | null = "";
    let time: string | null = "";
    let timezone: any = "";

    if (startDate !== null || startDate !== undefined) {
      let previewTime = moment.utc(startDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          let start: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
        }
      }
    }
    return (
      <Countdown
        date={Date.now() + seconds}
        className="countdownText"
        renderer={renderer}
        zeroPadTime={2}
      />
    );
  };

  const filterSort = (price: any, direction: any) => {
    let order = "";
    if (direction === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }
    sort(price, order);
  };

  const sort = (value: any, direction: any) => {
    sortData(value, direction);
  };

  const sortData = (values: any, direction: any) => {
    let tempArr: any;

    if (direction === "asc") {
      tempArr = [{ property: values, direction: "ASC" }];
      localStorage.setItem("annualdropDownFilterVal", JSON.stringify(tempArr));
    } else {
      tempArr = [{ property: values, direction: "DESC" }];
      localStorage.setItem("annualdropDownFilterVal", JSON.stringify(tempArr));
    }

    //reseting all the pagination and persisting values in the localstrorage

    if (selectcurrentApiStartCountValue === 0) {
      getAuctionProducts(auctionId, 0);
    } else {
      dispatch(resetAnnualCurrentApiStartCount());
    }
    // getAuctionProducts(auctionId, 0);
    selectPageUseRef.current = 0;
    localStorage.removeItem("currentStart");
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: "Serial No",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "perId",
      label: "Product Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionUserId",
      label: "Auction User Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "buyItNow",
      label: "Buy it now",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            background: "#E14843",
            color: "white",
            textAlign: "center",
          },
        }),
        setCellProps: () => ({
          style: { minWidth: "90px", maxWidth: "100px" },
        }),
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return (
            <div>
              <span>
                {convertedbuyItNowValue(
                  val.currency,
                  val.buyItNow,
                  val.exchangeRates[0].rate
                )}
              </span>
              <StyledBtnContainer>
                {val.buyItNow === 0 ||
                  val.sellerAuctionUserId === joinedAuctionUserId ||
                  isReadOnlyUser ||
                  val.buyItNow < val.currentBidPrice ? (
                  <StyledBuyitNow
                    style={{ color: "black", backgroundColor: "#d3d3d3" }}
                  >
                    Buy Now
                  </StyledBuyitNow>
                ) : (
                  <>
                    {val.annualProductState === "Ended" ? (
                      <StyledBuyitNow
                        style={{ color: "black", backgroundColor: "#d3d3d3" }}
                      >
                        Buy Now
                      </StyledBuyitNow>
                    ) : (
                      <StyledBuyitNow
                        onClick={() => {
                          setCurrentProductData(val);
                          openedBuyItNowPopupID.current = val;
                        }}
                      >
                        Buy Now
                      </StyledBuyitNow>
                    )}
                  </>
                )}
              </StyledBtnContainer>
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: `${t("common:Tender:tableColumnNames:ActionsTender")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <div title={`Note`} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon
                  icon={faEditProps}
                  style={{ color: "#014a99" }}
                  onClick={() =>
                    openProductDetail(
                      auctionId,
                      val.perId,
                      val.productStartDate
                    )
                  }
                />
              </div>
              <div
                title={`Add Product into Wishlist`}
                style={{ cursor: "pointer" }}
              >
                {isReadOnlyUser ? (
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    style={{ color: "gray" }}
                    onClick={() => {
                      renderReadOnlyUserToast(val.sellerAuctionUserId);
                    }}
                  />
                ) : (
                  <>
                    {val.sellerAuctionUserId !== joinedAuctionUserId ? (
                      <>
                        {getWatchedProduct(
                          val.watched,
                          val.perId,
                          auctionId,
                          val.perId
                        )}
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        style={{ color: "gray" }}
                        onClick={() => {
                          renderReadOnlyUserToast(val.sellerAuctionUserId);
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <div
                title={`Share Product`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  shareButtonAction(val.perId, val.productStartDate)
                }
              >
                <FontAwesomeIcon
                  icon={faShareSquareProps}
                  style={{ color: "#014a99" }}
                />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "productEndDate",
      label: `Time left`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          if (val.productExtended !== undefined) {
            if (val.productExtended) {
              return (
                <span style={{ color: "red" }}>
                  {productTimeLeft(val.productEndDate)}
                </span>
              );
            } else {
              return <span>{productTimeLeft(val.productEndDate)}</span>;
            }
          }
        },
      },
    },
    {
      name: "code",
      label: `Product Id`,
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return (
            <span
              className="linkCss"
              onClick={() =>
                openProductDetail(auctionId, val.perId, val.productStartDate)
              }
              onContextMenu={(event: any) =>
                rightClickEvent(event, val.perId, val.productStartDate)
              }
            >
              {val.code}
            </span>
          );
        },
      },
    },
    {
      name: "lab",
      label: `Lab`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          if (
            value !== "AGT" &&
            value !== "agt" &&
            value !== "" &&
            value !== undefined
          ) {
            return (
              <span
                className="linkCss"
                onClick={() =>
                  openCertificateLinkPopup(
                    value,
                    tableMeta.rowData[columnIndexes.certificate]
                  )
                }
              >
                {value}
              </span>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    },
    {
      name: "certificate",
      label: `Certificate`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "description",
      label: `Description`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productDescription",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "carat",
      label: `Carat`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "color",
      label: `Color`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "clarity",
      label: `Clarity`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "remarks",
      label: `Remarks`,
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return (
            <EllipsisText
              text={getDescription(
                val.parcelType,
                val.productDescription,
                val.remarks
              )}
              length={20}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "noOfPieces",
      label: `No Of Pieces`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return <>{val.noOfPieces !== 0 ? val.noOfPieces : "-"}</>;
        },
      },
    },
    {
      name: "shape",
      label: `Shape`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "sieve",
      label: `Sieve`,
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return <>{val.sieve !== "-" ? val.sieve : ""}</>;
        },
      },
    },
    {
      name: "cutGrade",
      label: `Cut Grade`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex.rowIndex];
          return <>{getAbbreviation(val?.cutGrade)}</>;
        },
      },
    },
    {
      name: "polish",
      label: `Polish`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "symmetryAbbreviation",
      label: `Symmetry`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "flour",
      label: `Flour`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return <div>{val.code}</div>;
        },
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:Tender:tableColumnNames:pricePct")}`,
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return (
            <div>
              {convertedValue(
                val.currency,
                val.caratMinimumPrice,
                val.exchangeRates[0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "myLatestBid",
      label: `${t("common:Tender:tableColumnNames:placeBid")}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "140px" },
        }),
        customBodyRenderLite: (data, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return (
            <>
              {isDataRefresh ? (
                <div>
                  {val.myLatestBid === undefined || val.myLatestBid === null
                    ? 0
                    : handelMyBidsLabel(
                      myLatestBidForProduct,
                      val.perId,
                      val.currency,
                      val.exchangeRates[0].rate
                    )}
                </div>
              ) : (
                <div>
                  {val.myLatestBid === undefined || val.myLatestBid === null
                    ? 0
                    : handelMyBidsLabel(
                      myLatestBidForProduct,
                      val.perId,
                      val.currency,
                      val.exchangeRates[0].rate
                    )}
                </div>
              )}
              {getBidNowButtonDisable(
                val.sellerAuctionUserId,
                joinedAuctionUserId,
                val.auctionUserId,
                auctionProducts[rowIndex],
                getReturnMyLatestStoredBid(myLatestBidForProduct, val.perId),
                val.currentBidPrice,
                val.perId,
                val.previousHighestBidder,
                val.holdPreviousBidder,
                rowIndex,
                val.annualProductState
              )}
            </>
          );
        },
      },
    },
    {
      name: "notes",
      label: `${t("common:Tender:tableColumnNames:notes")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "currentBidPrice",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return (
            <div>
              {convertedValue(
                val.currency,
                val.currentBidPrice,
                val.exchangeRates[0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rapnetPercentage",
      label: `Rap Off`,
      options: {
        filter: false,
        sort: true,
        customBodyRenderLite: (rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return <div>{getRapNet(val.rapnetPercentage)}</div>;
        },
      },
    },
    {
      name: "noOfBidders",
      label: `No Of Bidders / Bids`,
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return (
            <div>
              {getNoOfBiddersAndNoOfBids(val.noOfBidders, val.noOfBids)}
            </div>
          );
        },
      },
    },
    {
      name: "rowColor",
      label: "Bid Color",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "Exchange Rate",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
          let val: any = auctionProducts[rowIndex];
          return <div>{val.exchangeRates[0].rate}</div>;
        },
      },
    },
    {
      name: "parcelType",
      label: "",
      options: {
        display: false,
      },
    },

    {
      name: "noOfBids",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "watched",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sellerAuctionUserId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "rapnetPercentage",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "previousHighestBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productStartDate",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productExtended",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "holdPreviousBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const renderReadOnlyUserToast = async (perId: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (items.category === "sieveDiamonds") {
          if (perId === items.auctionUserId) {
            isAuctionUserId = true;
          }
        }
      });
    } else {
      isAuctionUserId = false;
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  //get bid now button disable
  const getBidNowButtonDisable = (
    sellerId: any,
    auctionUserIdForProduct: any,
    tableAuctionUserId: any,
    tableDataForUIBox: any,
    myBidsForProduct: any,
    currentBidPriceForProduct: any,
    currentproductId: any,
    previousBidderId: any,
    holdPreviousBidder: any,
    indexVal: any,
    annualProductState: any
  ) => {
    renderAutomaticBidPopup(tableDataForUIBox);
    if (isReadOnlyUser) {
      return (
        <StyledBtnContainer
          onClick={() => {
            renderReadOnlyUserToast(sellerId);
          }}
        >
          <StyledButtonGray>
            {t("common:inquiry:button:bidNow")}
          </StyledButtonGray>
        </StyledBtnContainer>
      );
    } else if (annualProductState === "Ended") {
      return (
        <StyledBtnContainer>
          <StyledButtonGray>
            {t("common:inquiry:button:bidNow")}
          </StyledButtonGray>
        </StyledBtnContainer>
      );
    } else {
      if (sellerId === auctionUserIdForProduct) {
        return (
          <StyledBtnContainer
            onClick={() => {
              renderReadOnlyUserToast(sellerId);
            }}
          >
            <StyledButtonGray>
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else {
        return (
          <StyledBtnContainer>
            <StyledButton
              onClick={() => handleBidClick(tableDataForUIBox, indexVal)}
            >
              {t("common:inquiry:button:bidNow")}
            </StyledButton>
          </StyledBtnContainer>
        );
      }
    }
  };

  //get My All MyBid
  const handelMyBidsLabel = (
    myStoredBids: any,
    currentProductId: any,
    currency: any,
    exchangeRate: any
  ) => {
    const Obj = myLatestBidForProduct?.filter(
      (item: any) => item.productId === currentProductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.productId;
    });

    return getMyLatestStoredBids(
      myLatestBidForProduct,
      currentProductId,
      currency,
      exchangeRate,
      mybidPriceforProduct
    );
  };

  //get Watched Product
  const getWatchedProduct = (
    watched: any,
    productId: any,
    auctionId: any,
    serialNo: any
  ) => {
    if (watched === true) {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => removeFromWatchlist(productId, auctionId)}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "gray" }}
          onClick={() => addToWatchList(productId, auctionId, serialNo)}
        />
      );
    }
  };

  const shareButtonAction = (perId: any, productStartDate: any) => {
    date = productStartDate.split(" ", 2);
    const { t } = props;
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
          ? "zh_CN"
          : "jp";
    // let languageCode = localStorage.getItem("language") === "EN"? "en" : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/${perId}/${date[0]}/detail`
    );
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const listGridview = () => {
    setUiBoxView(true);
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
    onGridSelectionChange(0);
    dispatch(resetIsGridView());
  };

  const Gridview = () => {
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
    onGridSelectionChange(0);
    dispatch(setIsGridView({ gridView: false }));
    setUiBoxView(false);
  };

  const getNoOfBiddersAndNoOfBids = (noOfBidders: any, noOfBids: any) => {
    return `${noOfBidders}/${noOfBids}`;
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    productStartDate: string
  ) => {
    date = productStartDate.split(" ", 2);
    props.history.push(
      `/365products/details/${auctionId}/${joinedAuctionUserId}/${perId}/${date[0]}/${isMypage}`
    );
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
  };

  const openDetailsView = (
    auctionId: string,
    perId: string,
    productStartDate: string
  ) => {

    date = productStartDate.split(" ", 2);
    window.open(
      `/${languageCode}/365products/details/${auctionId}/${joinedAuctionUserId}/${perId}/${date[0]}/${isMypage}`,
      "_blank"
    );
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
  };

  const rightClickEvent = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string,
    productStartDate: string
  ) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentProductId(productId);
    setProductStartDate(productStartDate);
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  //paggination and sorting issue
  const setItemsPerPageHandler = (itemsPerPage: number) => {
    localStorage.setItem("currentPageOfTenderAllProducts", "0");
    setCurrentPage(0);
    setItemsPerPage(itemsPerPage);
    itemsPerPageUseRef.current = itemsPerPage;
    dispatch(setAnnualItemPerPage({ itemsPerPage }));
    dispatch(resetAnnualCurrentApiStartCount());
  };

  function setCurrentPage(currentPage: number) {
    return currentPage;
  }

  function setItemsPerPage(itemsPerPage: number) {
    return itemPerPage(true, 0, itemsPerPage);
  }
  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      localStorage.setItem("annualitemsPerPage", JSON.stringify(itemPerPage));
      getUpdatedProducts(value, start, itemPerPage);
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    if (value === true) {
      getProducts(start, endRecords);
    }
  };

  const getProducts = (start: number, itemsPerPage: number) => {
    startCount = start;
    endCount = itemsPerPage;
    localStorage.setItem("currentStart", JSON.stringify(start));
    let currentPage = start / itemsPerPage;
    dispatch(setAnnualCurrentApiStartCount({ currentPage }));
    dispatch(setAnnualItemPerPage({ itemsPerPage }));
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
    dispatch(resetDiamondGridProductAllProductIndexes());
    dispatch(resetDiamondAllProductSelectAll());
    onGridSelectionChange(0);
    setIsLoading(true);
    if (currentPage === selectcurrentApiStartCountValue) {
      setIsLoading(false);
    }
  };

  const handleMyBids = (auctionsProduct: any) => {
    if (auctionsProduct.length > 0) {
      setAuctionProducts(auctionsProduct);
    }
  };

  const addToWatchList = (
    productId: string | number,
    auctionId: string | number,
    serialNo: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/watch`,
        "payload",
        (status: any, response: any) => {
          if (response !== undefined) {
            if (status === 200) {
              // callApi(payload);
              const newArr: any = auctionProducts.map((obj: any) => {
                if (obj.perId === productId) {
                  return {
                    ...obj,
                    watched: true,
                  };
                }
                return obj;
              });
              auctionProductResult = newArr;
              setAuctionProducts(() => newArr);
              setIsTableExpanded(false);
              setIsLoading(false);
              setOnAction(false);
              setShowAlertMessage(`${t("common:preview:addedToWatchMsg")}`);
              setAlertType("success");
              setOpenToast(true);
            }
          }
        }
      );
      setIsLoading(false);
    }
  };

  const removeFromWatchlist = (
    productId: string | number,
    auctionId: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/unwatch`,
        "payload",
        (status: any, response: any) => {
          if (status === 200) {
            // callApi(payload);
            const newArr: any = auctionProducts.map((obj: any) => {
              if (obj.perId === productId) {
                return {
                  ...obj,
                  watched: false,
                };
              }
              return obj;
            });
            auctionProductResult = newArr;
            setAuctionProducts(() => newArr);
            setIsTableExpanded(false);
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          }
        }
      );
      setIsLoading(false);
    }
  };

  //index of Mobile ui component
  const getBoxIndex = (boxIndex: any) => {
    length = auctionProducts.length;
    details = boxIndex;
    SelectedTableData = auctionProducts[boxIndex];
    setShowSinglebidPopup(true);
  };

  const closebidPopup = () => {
    setShowSinglebidPopup(false);
    setShowBulkBidPopup(false);
  };

  const mypageRedirectHandler = () => {
    dispatch(resetAnnualItemPerPage());
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
    dispatch(resetAnnualCurrentApiStartCount());
    localStorage.removeItem("selectedFilterSort");
    props.history.push("/my-page");
  };

  const productCart = () => {
    localStorage.removeItem("selectedFilterSort");
    dispatch(resetAnnualItemPerPage());
    dispatch(resetAnnualCurrentApiStartCount());
    PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
    props.history.push(`/productCart/${auctionId}/Diamonds`);
  };

  const exportExcel = (auctionProducts: any, auctionId: string) => {
    setIsExportDisable(true);
    Auth.postRequestForExcel(
      `${productDownload}?auctionId=${auctionId}&auctionType=Annual`,
      { ids: "" },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
          setTimeout(() => {
            setIsExportDisable(false);
          }, 5000);
        }
      }
    );
  };

  const selectAllInGridView = (data: any) => {
    let arr: any = [];
    if (!isReadOnlyUser) {
      console.log(auctionProducts);
      data.map((productVal: any, index: any) => {
        if (arr.length < 10) {
          if (productVal.sellerAuctionUserId === joinedAuctionUserId) {
            // Do something if current user is the seller
          } else if (productVal.annualProductState === "Ended") {
          } else {
            arr = [...arr, index];
          }
        }
      });
    } else {
      setShowAlertMessage(`You are the read only user.`);
      setAlertType("error");
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
      }, 3000);
    }

    if (isSelected) {
      setIsSelected(false);
      PubSub.publish("resetAnnualDiamsGridBoxIndex", []);
      arr = [];
      dispatch(resetDiamondGridProductAllProductIndexes());
      dispatch(resetDiamondAllProductSelectAll());
    } else {
      setIsSelected(true);
      dispatch(setDiamondGridProductAllProductIndexes({ indexes: arr }));
      dispatch(setDiamondAllProductSelectAll({ isSelected: true }));
    }
  };

  const onBuyItNowPopupHandler = (itemArr: any) => {
    setCurrentProductData(itemArr);
    openedBuyItNowPopupID.current = itemArr;
  }
  // buy it now button condition
  useEffect(() => {
    if (currentProductData !== null) {
      setIsBuyItNowModal(true);
    }
  }, [currentProductData]);

  const renderBuyItNowConfirmationModal = () => {
    return (
      <StyledModal
        open={isBuyItNowModal}
        onClose={() => {
          setCurrentProductData(null);
          openedBuyItNowPopupID.current = null;
          setIsBuyItNowModal(false);
        }}
      >
        <BuyItNowconfirmation
          closeModal={() => {
            setIsBuyItNowModal(false);
            setCurrentProductData(null);
            openedBuyItNowPopupID.current = null;
          }}
          itemDetails={currentProductData}
          auctionUserId={joinedAuctionUserId}
        />
      </StyledModal>
    );
  };

  const renderWinnerModal = () => {
    return (
      <StyledModal open={buyItWinnerPop}>
        <ViewBuyItNowWinnerModal
          closeModal={() => {
            setBuyItWinnerPop(false);
            setProductDetails("");
          }}
          name={productDetails}
        />
      </StyledModal>
    );
  };

  const bulkWatchlistHandler = () => {
    if (!isReadOnlyUser) {
      if (indexsForBulkBidItems.length > 0) {
        setShowBulkBidPopup(true);
      } else {
        setShowAlertMessage(`Please Select Product to watch.`);
        setAlertType("error");
        setOpenToast(true);
        setTimeout(() => {
          setOpenToast(false);
        }, 2000);
      }
    } else {
      setShowAlertMessage(`You are the read only user.`);
      setAlertType("error");
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
      }, 3000);
    }
  };

  return (
    <Grid>
      {apiTimeout === true ? (
        <ApiTimeout {...props} t={t} />
      ) : (
        <>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <StyledLayoutGridMain>
              {tabValue.allProducts ? (
                <StyledBoxContent title="Click here for advance search">
                  <StyledDropDown
                    src="/images/assets/doubledown.png"
                    alt="d"
                    style={{
                      transform: isFiltersOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                    onClick={handleFilters}
                  />
                </StyledBoxContent>
              ) : null}
              {tabValue.allProducts ? (
                <>
                  {isFiltersOpen && (
                    <div
                      style={{
                        marginTop: "10px",
                        padding: "20px",
                      }}
                    >
                      <TabLayout {...props} t={t} tabClick={tabItemClicks} />
                      {isShowParcelItem === false ? (
                        <ProductFilter
                          {...props}
                          t={t}
                          filteredAuctions={filteredAuction}
                          auctionId={auctionId}
                          annualAuction={true}
                          isPreviewPage={false}
                        />
                      ) : (
                        <ParcelItemFilter
                          {...props}
                          t={t}
                          filteredParcelAuctions={filteredAuction}
                          auctionId={auctionId}
                          annualAuction={true}
                          isPreviewPage={false}
                        />
                      )}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              <StyledHeaderContainer container={true} spacing={0}>
                <StyledCoreDataGridContainer container={true} spacing={0}>
                  <StyledActionBarContainer
                    container={true}
                    spacing={0}
                    className="coreActionBarContainer"
                  >
                    <StyledContainer>
                      <BasicSearchBox
                        autoCompleteSearch={autoCompleteSearch}
                        textSearchListing={textSearchListing}
                        setTextSearchArray={setTextSearchArray}
                      />
                    </StyledContainer>
                    <StyledContainer>
                      {notSmallDevices ? (
                        <>
                          {isGridView === true ? (
                            <StyledSelectAllButtonHeader
                              onClick={() => selectAllInGridView(auctionProducts)}
                            >
                              {t("common:newCommanTrans:selectAll")}
                            </StyledSelectAllButtonHeader>
                          ) : null}
                        </>
                      ) : (
                        <StyledSelectAllButtonHeader
                          onClick={() => selectAllInGridView(auctionProducts)}
                        >
                          {t("common:newCommanTrans:selectAll")}
                        </StyledSelectAllButtonHeader>
                      )}

                      <StyledButtonHeader onClick={mypageRedirectHandler}>
                        My-page
                      </StyledButtonHeader>

                      <AnnualActionPageSizeSelectorNew
                        setItemsPerPageHandler={setItemsPerPageHandler}
                        t={t}
                      />

                      <StyledButtonHeader onClick={productCart}>
                        {t("common:newCommanTrans:Cart")}
                      </StyledButtonHeader>
                    </StyledContainer>
                    <StyledContainer>
                      {isExportDisable ? (
                        <StyledIconImg
                          src="/images/assets/iconDownload.png"
                          alt="exportsheet"
                          title="Download Export"
                        />
                      ) : (
                        <StyledIconImg
                          src="/images/assets/iconDownload.png"
                          alt="exportsheet"
                          title="Download Export"
                          onClick={() => {
                            exportExcel(auctionProducts, auctionId);
                          }}
                        />
                      )}
                      {/* <StyledIconImg
                        src="/images/assets/bidIcon.png"
                        alt="bidIcon"
                        title="Select items to bulk prebid or watchlist"
                        onClick={bulkWatchlistHandler}
                      /> */}
                      {notSmallDevices ? (
                        <>
                          {isGridView ? (
                            <StyledIconImg
                              src="/images/assets/listViewIcon.png"
                              onClick={Gridview}
                              className="icon"
                              title="List view of Product"
                            />
                          ) : (
                            <StyledIconImg
                              src="/images/assets/gridViewIcon.png"
                              onClick={listGridview}
                              title="Grid view of Product"
                              className="icon"
                            />
                          )}
                        </>
                      ) : null}
                    </StyledContainer>
                  </StyledActionBarContainer>
                </StyledCoreDataGridContainer>
              </StyledHeaderContainer>
              <StyledLayoutGrid container={true}>
                <>
                  {isGridView === false && notSmallDevices && (
                    <MuiThemeProvider theme={getMuiTheme()}>
                      <MUIDataTable
                        title={""}
                        data={auctionProducts}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  )}

                  {isGridView === true
                    ? notSmallDevices && (
                      <>
                        <ProductGridDataParent
                          t={t}
                          data={auctionProducts}
                          totalElements={totalElement}
                          itemPerPage={itemPerPage}
                          value={getUpdatedProducts}
                          noProductFound={handleMyBids}
                          itemsPerPageUseRef={selectItemPerPageValue}
                          selectPageUseRef={selectPageUseRef.current}
                        >
                          {auctionProducts.length > 0 ? (
                            <ProductGridDataChildComponent
                              {...props}
                              watchlist={addToWatchList}
                              setShowBidPopupBidModal={getBoxIndex}
                              auctionName={auctionName}
                              auctionDate={date[0]}
                              t={t}
                              isAuctionPage={true}
                              unWatchlist={removeFromWatchlist}
                              auctionUserId={joinedAuctionUserId}
                              sortingValue={filterSort}
                              boxColor={boxColor}
                              myForProductAllProduct={myLatestBidForProduct}
                              bulkBidIndexHandler={onGridSelectionChange}
                              renderAutomaticBidPopupInMobile={
                                renderAutomaticBidPopupInMobile
                              }
                              propsUserId={joinedAuctionUserId}
                              renderReadOnlyUserToast={
                                renderReadOnlyUserToast
                              }
                              isCategoryPageType={true}
                              isRefreshData={getExtendedTimeGrid}
                              rightClickEvent={rightClickEvent}
                            />
                          ) : (
                            <Grid container={true} spacing={0}>
                              <StyledTextWrapper item xs={12}>
                                {t("common:preview:noProductFound")}
                              </StyledTextWrapper>
                            </Grid>
                          )}
                        </ProductGridDataParent>
                      </>
                    )
                    : null}

                  {notSmallDevices || (
                    <>
                      <ProductGridDataParent
                        t={t}
                        data={auctionProducts}
                        totalElements={totalElement}
                        itemPerPage={itemPerPage}
                        value={getUpdatedProducts}
                        noProductFound={handleMyBids}
                        itemsPerPageUseRef={selectItemPerPageValue}
                        selectPageUseRef={selectPageUseRef.current}
                      >
                        {auctionProducts.length > 0 ? (
                          <ProductGridDataChildComponent
                            {...props}
                            watchlist={addToWatchList}
                            setShowBidPopupBidModal={getBoxIndex}
                            auctionName={auctionName}
                            auctionDate={date[0]}
                            t={t}
                            boxColor={boxColor}
                            isAuctionPage={true}
                            unWatchlist={removeFromWatchlist}
                            auctionUserId={joinedAuctionUserId}
                            propsUserId={joinedAuctionUserId}
                            sortingValue={filterSort}
                            bulkBidIndexHandler={onGridSelectionChange}
                            myForProductAllProduct={myLatestBidForProduct}
                            renderAutomaticBidPopupInMobile={
                              renderAutomaticBidPopupInMobile
                            }
                            renderReadOnlyUserToast={renderReadOnlyUserToast}
                            isCategoryPageType={true}
                            isRefreshData={getExtendedTimeGrid}
                            rightClickEvent={rightClickEvent}
                          />
                        ) : (
                          <Grid container={true} spacing={0}>
                            <StyledTextWrapper item xs={12}>
                              {t("common:preview:noProductFound")}
                            </StyledTextWrapper>
                          </Grid>
                        )}
                      </ProductGridDataParent>
                    </>
                  )}
                </>
                <BulkBidFloatingIcon handleClick={bulkWatchlistHandler} />
                <Menu
                  keepMounted
                  open={mouseState.mouseY !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    mouseState.mouseY !== null && mouseState.mouseX !== null
                      ? { top: mouseState.mouseY, left: mouseState.mouseX }
                      : undefined
                  }
                >
                  <MenuItem
                    onClick={() =>
                      openDetailsView(auctionId, currentProductId, productdate)
                    }
                  >
                    Details
                  </MenuItem>
                </Menu>

                {onAction === true && (
                  <div className="loading">
                    <LoadingSpinner />
                  </div>
                )}

                {userBlock === true && (
                  <StyledModal open={userBlock}>
                    <BlockUserModal
                      t={t}
                      {...props}
                      closeModal={() => {
                        setUserBlock(false);
                      }}
                    />
                  </StyledModal>
                )}

                {showSinglebidPopup && (
                  <StyledModal
                    open={showSinglebidPopup}
                    onClose={closebidPopup}
                  >
                    <ProductBiddingUi
                      t={t}
                      {...props}
                      items={auctionProducts}
                      currentIndex={details}
                      PopupClose={closebidPopup}
                      handleBtnValue={handleBtnValue}
                      auctionDate={date[0]}
                      totalElements={totalElement}
                      endCount={endCount}
                      auctionUserId={joinedAuctionUserId}
                      length={length}
                      watchedFunction={addToWatchList}
                      unwatched={removeFromWatchlist}
                      boxColor={boxColor}
                      myLatestBidForProduct={myLatestBidForProduct}
                      onBuyItNowPopupHandler={onBuyItNowPopupHandler}
                    />
                  </StyledModal>
                )}
                {showBulkBidPopup && (
                  <StyledModal open={showBulkBidPopup} onClose={closebidPopup}>
                    <AnnualBidPopup
                      {...props}
                      items={auctionProducts}
                      popupClose={closebidPopup}
                      handleBtnValue={bulkBidHandler}
                      auctionUserId={joinedAuctionUserId}
                      length={length}
                      productIndexs={indexsForBulkBidItems}
                      auctionDate={date[0]}
                      myLatestBidForProducts={myLatestBidForProduct}
                      isJewellery={false}
                    />
                  </StyledModal>
                )}
                {openReadOnlyUserToast && (
                  <AlertReadOnlyUser
                    isReadOnlyUser={isReadOnlyUser}
                    isSeller={isAuctionUserId}
                  />
                )}
                {isBuyItNowModal &&
                  currentProductData !== null &&
                  renderBuyItNowConfirmationModal()}
                {buyItWinnerPop && renderWinnerModal()}
              </StyledLayoutGrid>
            </StyledLayoutGridMain>
          )}
        </>
      )}
    </Grid>
  );
};

export default DiamondProductAuction;

import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

// Styled component for the rounded box with fixed positioning
const StyledBox = styled(Box)`
  cursor: pointer;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #2660AD;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  box-shadow: 0px 3px 6px #00000029;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 80px;
    height: 80px;
  }
`;

// Styled component for the image
const StyledImage = styled("img")`
  padding: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface IBidIconrops {
  handleClick: () => void;
}

const BulkBidFloatingIcon = ({ handleClick }: IBidIconrops) => {
  return (
    <StyledBox>
      <StyledImage
        src="/images/assets/bidIcon.png"
        alt="bidIcon"
        title="Select items to bulk prebid or watchlist"
        onClick={handleClick}
      />
    </StyledBox>
  );
};

export default BulkBidFloatingIcon;

import moment from 'moment';
import React from 'react'
import Countdown from 'react-countdown';
interface Iprops {
    items: any;
}

let seconds: number;

const DetailsPageTimer = ({ items }: Iprops) => {
    const productTimeLeft = (startDate: any) => {
        let date: string | null = "";
        let time: string | null = "";
        if (startDate !== null || startDate !== undefined) {
            let previewTime = moment.utc(startDate);
            if (previewTime !== null || previewTime !== undefined) {
                let result = moment.tz(previewTime, "Asia/Kolkata");
                let resultFormatter = result.format();
                if (resultFormatter !== "Invalid date") {
                    let splitDateTime = resultFormatter.split("T", 2);
                    let splitTime: any = "";
                    if (splitDateTime[1].includes("+")) {
                        splitTime = splitDateTime[1].split("+", 2);
                    } else {
                        splitTime = splitDateTime[1].split("-", 2);
                    }
                    date = splitDateTime[0];
                    time = splitTime[0];
                    let dateTime = `${date} ${time}`;
                    let end: any = moment(dateTime);
                    let start: any = moment(
                        new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
                    );
                    var timeDiff = end - start;
                    seconds = Math.round(timeDiff);
                }
            }
        }
        return (
            <Countdown
                date={Date.now() + seconds}
                className="countdownText"
                renderer={renderer}
                zeroPadTime={2}
            />
        );
    };

    const renderer = ({
        formatted: { days, hours, minutes, seconds },
        completed,
    }: any) => {
        if (completed) {
            if (
                days === "00" &&
                hours === "00" &&
                minutes === "00" &&
                seconds === "00"
            ) {
                return <span>00:00:00:00</span>;
            }
        } else {
            return (
                <span>
                    {days}:{hours}:{minutes}:{seconds}
                </span>
            );
        }
    };

    return (
        <>
            {items?.annualProductState === "Started" && (
                <span style={{ color: "red", marginLeft: '10px' }}>
                    <strong>Bidding ends in {productTimeLeft(items?.productEndDate)}</strong>
                </span>
            )}
        </>
    )
}

export default DetailsPageTimer